.container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	height: 100vh; /* Full viewport height */
	width: 100vw; /* Full viewport width */
	align-items: center;
	justify-content: center;
	text-align: center;
}

.backgroundImage {
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1; /* Ensure content is above the background image */
}

.title {
	letter-spacing: 10px;
	background: linear-gradient(180deg, #c3c3c3 0%, #444242 50%, #c3c3c3 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font: 700 100px Inter, sans-serif;
	margin: 0;
}

.title span {
	display: block; /* Each word on a separate line */
}

@media (max-width: 991px) {
	.title {
		font-size: 40px;
	}
}

.scrollText {
	color: #1d1d1d;
	letter-spacing: 2.4px;
	margin-top: 40px; /* Adjusted margin to move text lower */
	font: 100 30px Inter, sans-serif;
}

@media (max-width: 991px) {
	.scrollText {
		margin-top: 20px; /* Adjusted margin for smaller screens */
	}
}

.scrollIcon {
	aspect-ratio: 1;
	object-fit: auto;
	object-position: center;
	width: 36px;
	margin-top: 20px; /* Adjusted margin to move icon lower */
	animation: jump 2s infinite; /* Added animation */
}

@keyframes jump {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px); /* Adjust amplitude */
	}
}

.contactInfo {
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: white;
	font-family: 'Inter', sans-serif;
	font-weight: 300;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.contactRow {
	display: flex;
	align-items: center;
	gap: 10px;
}

.icon {
	width: 30px;
	height: 30px;
}
