.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-color: #ffffff;
	text-align: center;
}

.image {
	max-width: 100%;
	height: auto;
}

.text {
	color: white;
	font-family: 'Inter', sans-serif;
	font-weight: 300;
	font-size: 24px;
	margin-top: 20px;
}
